@import '../master.scss';

.projects-main-container{
    width: 100%;
    background-color: $tier2;
    display: flex;
    justify-content: center;
    .projects-container{
        width: 90%;
        max-width: 1400px;
        color: $secondary;
        padding: 40px 0;
        .projects-icon-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 10px;
            svg{
                height: 50px;
                width: 50px;
            }
        }
        .projects-list-container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .projects-item{
                background-color: $tier4;
                margin: 10px;
                width: 30%;
                border: 1px solid $primary;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                overflow: hidden;
                .dot{
                    background-color: $secondary;
                }
                               
                .project-item-img{
                    width: 100%;
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    background-color: $primary;
                    img{
                        height: 100%;
                        width: auto;
                        max-width: 100%;
                    }
                    
                }
                .project-info{
                    width: 100%;
                    border-radius: 0 0 3px 3px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    .project-title{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 10px;
                        margin: 10px 0;
                    }
                    .project-description{
                        padding: 10px;
                        text-align: justify;
                        min-height: 125px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        p{
                            color: $primary;
                            margin-bottom: 5px;
                        }
                        .techno{
                            font-weight: bold;
                            font-style: italic;
                            text-align: center;
                        }
                    }
                    
                    .project-links-container{
                        display: flex;
                        justify-content: flex-end;
                        .project-link{
                            display: flex;
                            margin: 5px;
                            &:hover{
                                a{
                                    color: $primary;
                                }
                                color: $primary;
                            }
                            a{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: $secondary;
                                svg{
                                    height: 30px;
                                    width: 30px;
                                }
                            }
                            p{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: 0 5px;
                            }
                        }
                    }
                }
                
            }
        }

    }
}

@media (max-width: 1024px) {
    .projects-main-container{
        .projects-container{
            width: 100%;
        }
    }
}
@media  (max-width: 900px) {
    .projects-main-container{
        .projects-container{
            .projects-list-container{
                .projects-item{
                    width: 45%;
                }
            }
        }
    }
}
@media  (max-width: 500px) {
    .projects-main-container{
        .projects-container{
            .projects-list-container{
                .projects-item{
                    width: 100%;
                }
            }
        }
    }
}