@import '../master.scss';

.skills-main-container{
    width: 100%;
    background-color: $tier3;
    color: $secondary;
    display: flex;
    justify-content: center;
    padding: 40px 0;
    border-bottom: 1px solid $primary;
    .skills-container{
        width: 80%;
        max-width: 1400px;
    }
    .skills-icon-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
        svg{
            height: 50px;
            width: 50px;
        }
    }
    .skills-block{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .skills-underblock{
            width: 30%;
            display: flex;
            flex-direction: column;
            h3{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 10px;
                svg{
                    margin: 10px;
                    height: 25px;
                    width: 25px;
                }
            }
            .skills-item{
                background-color: $tier2;
                border-radius: 5px;
                border: 1px solid $primary;
                height: 50px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                svg{
                    margin: 10px;
                }
            }
            
        }
    }
    
}
@media (max-width: 680px) {
    .skills-main-container{
        .skills-container{
            width: 90%;
        }
    }
}


@media (max-width: 610px) {
    .skills-main-container{
        .skills-block{
            flex-direction: column;
            .skills-underblock{
                width: 100%;
            }
        }

    }
}
@media (max-width: 375px) {
    .skills-main-container{
        .skills-icon-container{
            h2{
                font-size: 22px;
            }
        }
    }
}
@media (max-width: 320px) {
    .skills-main-container{
        .skills-icon-container{
            h2{
                font-size: 18px;
            }
        }
    }
}