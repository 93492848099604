//color
$primary : black;
$secondary : #8CCE1F;
$tier : #1F2937;
$tier2 : rgb(36, 36, 36);
$tier3 : rgb(45, 45, 45);
$tier4 : rgb(75, 75, 75);

// font
@import url('https://fonts.googleapis.com/css2?family=Righteous&family=Roboto+Condensed:ital,wght@0,400;0,700;1,400;1,700&display=swap');
//font-family: 'Righteous', cursive;
//font-family: 'Roboto Condensed', sans-serif;