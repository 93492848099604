@import './master.scss';

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto Condensed', sans-serif;
}

body{
    background-color: $tier;
}