@import "../master.scss";

@keyframes slideleft {
    from {
      right: -50%;
    }
  
    to {
      right: 0;
    }
}
@keyframes slideright {
    from {
      right: 0;
    }
  
    to {
      right: -50%;
    }
}

.navbar-container{
    width: 100%;
    background-color: $primary;
    color: $secondary;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $secondary;
    padding: 0 20px;
    border-bottom: 2px solid $secondary;
    position: fixed;
    z-index: 10;
    .navbar-title{
        font-size: 35px;
    }
    .menu-desktop{
        display: flex;
        align-items: center;
        a{
            color: $secondary;
            text-decoration: none;
            font-size: 16px;
            display: flex;
            align-items: center;
            p{
                padding: 0 5px;
                height: 30px;
                margin: 10px;
                display: flex;
                align-items: center;
                &:hover{
                    color: white;
                    cursor: pointer;
                    border-bottom: 2px white solid;
                    margin-bottom: 8px;
                }
                svg{
                    margin-right: 10px;
                }
            }
            .btn-cv{
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $secondary;
                width: 80px;
                color: $primary;
                border-radius: 3px;
                &:hover{
                    border-bottom: none;
                    margin-bottom: 10px;
                    background-color: $primary;
                    border: 2px solid $secondary;
                }
            }
        }
    }
    .menu-mobile{
        display: none;
        .menu-mobile-icon{
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
        .drop-down-menu{
            width: 40%;
            height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            border: 2px solid $secondary;
            position: absolute;
            background-color: $primary;
            right: 0;
            top: 52px;
            animation-duration: 1s;
            animation-name: slideleft;  
            a{
                color: $secondary;
                text-decoration: none;
                font-size: 16px;
                width: 100%;
                &:hover{
                    color: white;
                    cursor: pointer;
                }
                p{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0 10px;
                    svg{
                        margin-right: 10px;
                    }
                }
                .btn-cv{
                    background-color: $secondary;
                    color: $primary;
                    width: 80%;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 3px;
                    &:hover{
                        background-color: $primary;
                        color: $secondary;
                        border: 2px solid $secondary;
                    }
                }
            }  
        }
        .slideright{
            animation-duration: 1s;
            animation-name: slideright; 
        }
    }
}


@media (max-width: 930px) {
    .navbar-container{
        flex-direction: column;
    }
    
}
@media (max-width: 660px) {
    .navbar-container{
        flex-direction: row;
        .menu-desktop{
            display: none;
        }
        .menu-mobile{
            display: initial;
        }
    }

}
@media (max-width: 425px) {
    .navbar-container{
        .menu-mobile{
            .drop-down-menu{
                width: 40%;
            }
        }
    }

}
@media (max-width: 375px) {
    .navbar-container{
        .navbar-title{
            font-size: 25px;
        }
        .menu-mobile{
            .drop-down-menu{
                width: 50%;
            }
        }
    }

}