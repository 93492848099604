@import '../master.scss';

.roadmap-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $tier2;
    .roadmap-title{
        color: $secondary;
        background-color: $primary;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $secondary;
        font-size: 15px;
    }
    .roadmap-image{
        padding: 30px;
        img{
            border: 2px solid $secondary;
            width: 100%;
            border-radius: 5px;
        }
    }
}