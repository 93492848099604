@import '../master.scss';

.admin-page-main-container{

    background-color: $tier3;
    width: 100%;
    min-height: 100vh;
    color: $secondary;
    display: flex;
    flex-direction: column;
    align-items: center;
    .admin-page-title{
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 30px;
        h1{
            text-align: center;
        }
        svg{
            width: 50px;
            height: 50px;
            margin: 0 20px;
        }
    }
    
        .form-admin{
            width: 30%;
            border: 1px solid $primary;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: $tier2;
            .form-admin-line{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                label{
                    margin: 10px;
                }
                input{
                    margin: 10px;
                    text-align: center;
                    font-size: 18px;
                }
            }
            button{
                margin: 10px;
                width: 100px;
                height: 30px;
            }
        }
        .error-message{
            background-color: $tier2;
            margin: 20px;
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            border: 1px solid $primary;
            p{
                margin: 10px;
            }
        }
        .messages-container{
            border: 1px solid $primary;
            border-radius: 3px;
            background-color: $tier2;
            width: 80%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin: 20px;
            padding: 20px;
            .message-container{
                border: 1px solid $primary;
                border-radius: 3px;
                background-color: $tier4;
                margin: 10px 0;
                .name-line{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid $primary;
                    background-color: $tier3;
                    min-height: 30px;
                    text-decoration: underline;
                }
                .mail-line{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    min-height: 30px;
                    padding-right: 20px;
                    background-color: $tier3;

                }
                .message-line{
                    border-bottom: 1px solid $primary;
                    min-height: 50px;
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    text-align: justify;
                }
            }
        }
    
}
@media (max-width: 1024px) {
    .admin-page-main-container{
        .form-admin{
            width: 50%;
        }
        .error-message{
            width: 50%;
        }
    }
}
@media (max-width: 465px) {
    .admin-page-main-container{
        .form-admin{
            width: 70%;
        }
        .error-message{
            width: 70%;
        }
    }
}
@media (max-width: 350px) {
    .admin-page-main-container{
        .form-admin{
            width: 90%;
        }
        .error-message{
            width: 90%;
        }
    }
}