@import '../master.scss';


.about-main-container{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $tier2;
    padding: 60px 0 20px 0;
    border-bottom: 1px solid $primary;
   .about-container{
    width: 80%;
    max-width: 1400px;
    min-height: 600px;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .about-left-block{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 45%;
        height: 100%;
        color: $secondary;
        p{
            text-align: justify;
            margin-top: 20px;
            line-height: 25px;
        }
        h1{
            font-size: 30px;
        }
    }
    .about-right-block{
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .img-container{
            width: 90%;
            img{
                max-width: 100%;
                max-height: 100%;
                border-radius: 3px;
                border: 2px solid $secondary;
            }
        }
        .icons-container{
            margin-top: 20px;
            width: 100%;
            display: flex;
            justify-content: center;
            a{
                color: $secondary;
                &:hover{
                    color: white;
                }
            }
            svg{
                width: 50px;
                height: 50px;
                margin: 0 20px;
            }
        }
    }
} 
}


@media (max-width: 630px) {
    .about-main-container{
        
        .about-container{
            flex-direction: column-reverse;
            .about-left-block{
                width: 100%;
                margin-top: 20px;
            }
            .about-right-block{
                width: 100%;
            }
        }
    }
}