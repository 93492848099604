@import '../master.scss';

.contact-main-container{
    width: 100%;
    background-color: $tier3;
    border-top: 1px solid $primary;
    color: $secondary;
    display: flex;
    justify-content: center;
    padding: 40px 0;
    .contact-container{
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contact-icon-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
        svg{
            height: 50px;
            width: 50px;
        }
    }
    .contact-form{
        background-color: $tier2;
        border-radius: 3px;
        border: 1px solid $primary;
        width: 50%;
        max-width: 600px;
        padding: 30px;
        form{
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            .form-line{
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 0 10px;
                margin: 10px 0;
                
                input{
                    margin: 10px 0;
                    height: 30px;
                    padding-left: 10px;
                    background-color: $tier3;
                    border-style: none;
                    border-radius: 3px;
                    border: 1px solid $primary;
                    color: $secondary;

                    &:focus{
                        outline: none;
                        background-color: $secondary;
                        color: $primary;
                    }
                }
                textarea{
                    margin: 10px 0;
                    border: 3px solid transparent;
                    border-radius: 3px;
                    padding: 10px;
                    background-color: $tier3;
                    border: 1px solid $primary;
                    color: $secondary;
                    resize: none;
                    width: 100%;
                    &:focus{
                        outline: none;
                        background-color: $secondary;
                        color: $primary;
                    }
                }
            }
            button{
                background-color: $secondary;
                width: 100px;
                height: 30px;
                border-radius: 3px;
                border: none;
                border-color: 3px solid $primary;
                &:hover{
                    color: $secondary;
                    background-color: $primary;
                    border: 2px solid  $secondary;
                }
            }
            .success-message{
                height: 30px;
                text-align: center;
            }
        }
    }
    .contact-block{
        background-color: $tier2;
        width: 50%;
        max-width: 600px;
        border: 1px solid $primary;
        margin: 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 3px;
        a{
            text-decoration: none;
            color: $secondary;
            &:hover{
                color: white;
            }
        }
        svg{
            height: 30px;
            width: 30px;
            margin-right: 10px;
        }
        p{
            display: flex;
            align-items: center;
            margin: 10px;
        }
    }
}

@media (max-width: 1024px) {
    .contact-main-container{
        .contact-container{
            .contact-form{
                width: 60%;
            }
            .contact-block{
                width: 60%;
            }
        }
    }
}
@media (max-width: 850px) {
    .contact-main-container{
        .contact-container{
            .contact-block{
                flex-direction: column;
                width: 60%;
            }
        }
    }
}
@media (max-width: 768px) {
    .contact-main-container{
        .contact-container{
            .contact-form{
                width: 70%;
            }
            .contact-block{
                width: 70%;
            }
        }
    }
}
@media (max-width: 600px) {
    .contact-main-container{
        .contact-container{
            .contact-form{
                width: 95%;
            }
            .contact-block{
                width: 95%;
            }
        }
    }
}